<template>
	<header>
		<nav class="navbar navbar-expand-lg">
			<div class="container-fluid ps-2">
				<router-link class="navbar-brand text-light" to="/">
                    <img class="navbar-logo" src="../assets/logo.png" alt="Chayxana_N1"/>
                </router-link>
				<button @click="isCloseNavbar = !isCloseNavbar"
                        class="navbar-toggler bg-light" type="button" data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
					<span v-if="!isCloseNavbar" class="navbar-toggler-icon"></span>
                    <img v-if="isCloseNavbar" width="30" src="../assets/close-icon.svg" alt="Whatsapp"/>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<div class="mx-auto"></div>
					<ul class="navbar-nav">
						<li class="nav-item">
							<router-link class="nav-link text-white" to="#">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
									<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
								</svg>
								Ежедневно с 09:00 до 24:00
							</router-link>
						</li>
						<li class="nav-item">
							<a class="nav-link text-white" href="tel:+79610438408">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-inbound-fill" viewBox="0 0 16 16">
									<path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0"/>
								</svg>
								+7-961-043-84-08
                            </a>
						</li>
						<li class="nav-item">
							<div class="d-inline-flex nav-link pe-0" role="search">
								<div class="input-group">
                                    <input type="text" class="form-control" placeholder="Поиск"
                                            v-model="selectedProduct"
                                            @input="filterList"
                                    />
                                    <button class="btn bg-white rounded-end-1  " type="button" id="button-addon2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                        </svg>
                                    </button>
                                    <ul v-if="filteredList.length" class="dropdown-menu show w-100">
                                        <li
                                                v-for="(item, index) in filteredList"
                                                :key="index"
                                                class="dropdown-item"
                                                @click="selectItem(item)"
                                        >
                                            {{ item.name }}
                                        </li>
                                    </ul>
                                </div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/plugins/vuex/router";

export default {
    // eslint-disable-next-line
    name: "Header",
    data() {
        return {
            isCloseNavbar: false,
            selectedProduct: '',
            filteredList: []
        }
    },
    computed: {
        ...mapGetters(['getProductsAllName'])
    },
    methods: {
        ...mapActions(['fetchAllProductsName', 'fetchProducts']),
        filterList(productName) {
            this.filteredList = this.getProductsAllName.filter(item =>
                item.name.toLowerCase().includes(this.selectedProduct.toLowerCase())
            );
            router.push({path: `/products/${this.getProductsAllName[0].menu.id}`, query: {productName: productName.data}})
        },
        selectItem(product) {
            this.selectedProduct = product.name;
            this.filteredList = [];
            router.push({path: `/products/${product.menu.id}`, query: {productName: product.name}})
        }
    },
    mounted() {
        this.fetchAllProductsName()
    }
}
</script>

<style scoped>
header {
    background: linear-gradient(365deg, #000000 50%, #ff6f33)

}

.navbar-brand {
  font-weight: bold;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1050;
    max-height: 200px;
    overflow-y: auto;
}

.navbar-logo {
    width: 200px;
}
@media (max-width: 800px) {
    header {
        background: linear-gradient(377deg, #000000 52%, #ff6f33)
    }
    .navbar-logo {
        width: 100px;
    }
}

@media (max-width: 1024px) {
    .navbar-logo {
        width: 150px;
    }
}
</style>