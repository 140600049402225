import {createApp} from 'vue'
import App from './App.vue'
import store from "@/plugins/vuex/store";
import router from "@/plugins/vuex/router";
import "bootstrap/dist/css/bootstrap.css";
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

createApp(App)
    .use(router)
    .use(store)
    .directive("popover", {
        mounted(el) {
            new bootstrap.Popover(el);
        },
    })
    .mount('#app')
