<template>
    <div class="container-app">
        <Header/>
        <router-view/>
        <Footer/>
        <FooterNavbar/>
    </div>
</template>

<script>
import Header from './components/Header.vue'
import FooterNavbar from "@/components/FooterNavbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
	Footer,
    FooterNavbar,
    Header
  }
}
</script>

<style>
.row {
	margin: 0 !important;
}

.container-app {
    font-family: "Lucida Console", "Courier New", monospace;
    max-width: 1920px;
    margin: 0 auto;
}
</style>
