import getRequests from "@/plugins/requests/getRequests";

export default {
    actions: {
        fetchProducts(context, menuId) {
            return getRequests('/products?pagination=false&menu=' + menuId, 'updateProducts', context)
        },
        fetchFilteredProducts(context, filterData) {
            return getRequests('/products?pagination=false&name=' + filterData, 'updateFilteredProducts', context)
        },
        fetchAllProductsName(context) {
            return getRequests('/products/all_name?pagination=false', 'updateProductsAllName', context)
        },
    },

    mutations: {
        updateProducts(state, products) {
            state.products = products
        },
        updateFilteredProducts(state, filterProducts) {
            state.filterProducts = filterProducts
        },
        updateProductsAllName(state, productsAllName) {
            state.productsAllName = productsAllName
        }
    },

    state: {
        products: {
            models: [],
            totalItems:0
        },
        filterProducts: {
            models: [],
            totalItems:0
        },
        productsAllName: {
            models: [],
            totalItems:0
        },
    },

    getters: {
        getProducts(state) {
            return state.products.models
        },
        getFilteredProducts(state) {
            return state.filterProducts.models
        },

        getProductsAllName(state) {
            return state.productsAllName.models
        }
    }
}
